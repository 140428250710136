import "./profile.scss";

import { EditNote, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Grid,
	Box,
	IconButton,
	FormControl,
	FormLabel,
	Input,
	Button,
	FormHelperText,
	Typography,
} from "@mui/joy";
import { useEffect, useState } from "react";
import Customer from "../../../interfaces/customer";
// import InputMask from "react-input-mask";
import * as React from "react";
import { IMaskInput } from "react-imask";
import { ModalForm } from "../../global_components/Modal";

//MASCARA DO INPUT
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const TextMaskAdapter = React.forwardRef<HTMLElement, CustomProps>(function TextMaskAdapter(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="(00) 00000-0000"
			definitions={{
				"#": /[1-9]/,
			}}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export default function ProfileForm(props: {
	customerInfo: Customer;
	setCustomer: any;
	handleEdit: () => void;
	handleCancelOperation: () => void;
}) {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [passwordModal, setPasswordModal] = useState<boolean>(false);
	const [emailModal, setEmailModal] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [tempPassword, setTempPassword] = useState<string>();
	const [tempEmail, setTempEmail] = useState<string>();
	const [tempPhone, setTempPhone] = useState<string>(props.customerInfo.Phone);
	const [enableFields, setEnableFields] = useState<boolean>(false);

	// function handlePasswordValidation() {
	// 	if (tempPassword === props.customerInfo.Password) {
	// 		console.log("foi")
	// 		console.log(tempPassword)
	// 		setEnableFields(true)
	// 	} else {
	// 		console.log("nao foi")
	// 		console.log(tempPassword)

	// 	}
	// }


	useEffect(() => {
		props.customerInfo.Phone = tempPhone;
		props.setCustomer({ ...props.customerInfo });
	}, [tempPhone]);

	return (
		<Grid
			container
			columnSpacing={4}
			rowSpacing={2}
			sx={{ backgroundColor: "var(--joy-palette-neutral-50)", borderRadius: "1rem" }}
		>
			<Grid md={12} xs={12}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography level="h4" sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
						<Person />
						Perfil
					</Typography>
					<IconButton onClick={() => setIsEdit(!isEdit)} color="primary">
						<EditNote />
					</IconButton>
				</Box>
			</Grid>
			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>Nome</FormLabel>
					<Input
						required
						color="primary"
						placeholder="Nome"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={props.customerInfo?.Name}
						onChange={(evt) => {
							props.customerInfo.Name = evt.target.value;
							props.setCustomer({ ...props.customerInfo });
						}}
					/>
				</FormControl>
			</Grid>
			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>E-mail</FormLabel>
					<Input
						color="primary"
						placeholder="Email"
						variant="outlined"
						disabled={true}
						value={props.customerInfo?.Email}
					/>
					<FormHelperText>
						<Button onClick={() => setEmailModal(true)}>Alterar e-mail</Button>
					</FormHelperText>
				</FormControl>
			</Grid>
			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>Telefone</FormLabel>
					<Input
						color="primary"
						placeholder="Telefone"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={tempPhone}
						onChange={(evt) => setTempPhone(evt.target.value)}
						slotProps={{ input: { component: TextMaskAdapter } }}
					/>
				</FormControl>
			</Grid>
			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>Segurança</FormLabel>
					<FormHelperText>
						<Button onClick={() => setPasswordModal(true)}>Alterar senha</Button>
					</FormHelperText>
				</FormControl>
			</Grid>
			{isEdit && (
				<>
					<Grid md={8} xs={8}></Grid>
					<Grid md={4} xs={4} sx={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}>
						<Button
							color="neutral"
							onClick={() => {
								props.handleCancelOperation();
								setIsEdit(false);
							}}
						>
							Cancelar
						</Button>
						<Button
							onClick={() => {
								props.handleEdit();
								setIsEdit(false);
							}}
							color="secondary"
						>
							Salvar
						</Button>
					</Grid>
				</>
			)}


			{/* Modal de alteração de senha */}
			{/* <ModalForm
				openModal={passwordModal}
				modalTitle="Alteração de senha"
				confirmText="Salvar"
				handleConfirmation={() => console.log("confirm")}
				handleCancelOperation={() => {
					console.log("cancel");
					setPasswordModal(false);
				}}
				handleCloseModal={() => setPasswordModal(false)}
			>
				<FormControl>
					<FormLabel>Senha atual</FormLabel>
					<Input
						type={showPassword ? "text" : "password"}
						value={tempPassword}
						endDecorator={
							<IconButton onClick={() => setShowPassword(!showPassword)}>
								{showPassword !== true ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
							</IconButton>
						}
						onChange={(evt) => { setTempPassword(evt.target.value) }}
					>
						{tempPassword}
					</Input>
				</FormControl>
				<FormControl>
					<FormLabel>Nova senha</FormLabel>
					<Input
						type={showPassword ? "text" : "password"}
						endDecorator={
							<IconButton onClick={() => setShowPassword(!showPassword)}>
								{showPassword !== true ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
							</IconButton>
						}
					>
						{props.customerInfo?.Password}
					</Input>
				</FormControl>
				<FormControl>
					<FormLabel>Digite novamente</FormLabel>
					<Input
						type={showPassword ? "text" : "password"}
						endDecorator={
							<IconButton onClick={() => setShowPassword(!showPassword)}>
								{showPassword !== true ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
							</IconButton>
						}
					>
						{props.customerInfo?.Password}
					</Input>
				</FormControl>
			</ModalForm> */}

			{/* Modal de alteração de email */}
			{/* <ModalForm
				openModal={emailModal}
				modalTitle="Alterar e-mail"
				confirmText="Alterar"
				handleConfirmation={() => console.log("sarvou")}
				handleCancelOperation={() => setEmailModal(false)}
				handleCloseModal={() => setEmailModal(false)}
			>
				<FormControl>
					<FormLabel>Digite sua senha</FormLabel>
					<Input
						id="emailReset"
						type={showPassword ? "text" : "password"}
						value={tempPassword}
						onChange={(evt) => handlePasswordValidation()}
						endDecorator={
							<IconButton onClick={() => setShowPassword(!showPassword)}>
								{showPassword !== true ? <Visibility color="primary" /> : <VisibilityOff color="primary" />}
							</IconButton>
						}
					>
						{tempPassword}
					</Input>
				</FormControl>
				<FormControl>
					<FormLabel>Novo e-mail</FormLabel>
					<Input
						value={tempEmail}
						type={"email"}
						disabled={enableFields ? false : true}
						onChange={(evt) => { props.customerInfo.Email = evt.target.value; props.setCustomer({ ...props.customerInfo }) }}
					>
						{tempEmail}
					</Input>
				</FormControl>
			</ModalForm> */}
		</Grid>
	);
}
