import './CardInfo.scss';

import { Box, Button, Card, CardActions, CardContent, Divider, IconButton, Tooltip, Typography } from "@mui/joy";

export default function CardInfo(props: {
    cardTitle: string,
    cardActions: boolean,
    firstBtnTitle?: string,
    secondBtnTitle?: string,
    isIconButton?: boolean,
    haveSecondBtn?: boolean,
    firstIcon?: any,
    firstIconTitle?: string,
    firstIconColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    firstIconTooltipColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    firstIconVariant?: "outlined" | "soft" | "plain" | "solid",
    firstIconTooltipVariant?: "outlined" | "soft" | "plain" | "solid",
    secondIcon?: any,
    secondIconTitle?: string,
    secondIconColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    secondIconTooltipColor?: "primary" | "secondary" | "danger" | "warning" | "success" | "neutral",
    secondIconVariant?: "outlined" | "soft" | "plain" | "solid",
    secondIconTooltipVariant?: "outlined" | "soft" | "plain" | "solid",
    handleFirstBtn?: () => void,
    handleSecondBtn?: () => void,
    handleFirstIcon?: () => void,
    handleSecondIcon?: () => void,
    children: any,
}) {

    return (
        <Card className="cards">
            <CardContent className="cardContent">
                <CardContent className="cardTitle">
                    <Typography level='title-lg'>{props.cardTitle}</Typography>
                </CardContent>
                <Divider />
                <CardContent className="cardBody" >
                    {props.children}
                </CardContent>
            </CardContent>
            {
                props.cardActions &&
                <CardActions>
                    {
                        props.isIconButton !== true &&
                        <Box>
                            <Button onClick={() => props.handleFirstBtn}>{props.firstBtnTitle}</Button>
                            {
                                props.haveSecondBtn &&
                                <Button onClick={() => props.handleSecondBtn}>{props.secondBtnTitle}</Button>
                            }
                        </Box>
                    }
                    {
                        props.isIconButton &&
                        <Box>
                            <Tooltip title={props.firstIconTitle} size="sm" color={props.firstIconTooltipColor} variant={props.firstIconTooltipVariant}>
                                <IconButton onClick={() => props.handleFirstIcon} color={props.firstIconColor} variant={props.firstIconVariant}>
                                    {props.firstIcon}
                                </IconButton>
                            </Tooltip>
                            {
                                props.haveSecondBtn &&
                                <Tooltip title={props.secondIconTitle} size="sm" color={props.secondIconTooltipColor} variant={props.secondIconTooltipVariant}>
                                    <IconButton onClick={() => props.handleSecondIcon} color={props.secondIconColor} variant={props.secondIconVariant}>
                                        {props.secondIcon}
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    }
                </CardActions>
            }
        </Card>
    )
}