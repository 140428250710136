import * as React from "react";
import { EditNote, Home } from "@mui/icons-material";
import { Grid, Box, IconButton, FormControl, FormLabel, Input, Button, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import Customer from "../../../interfaces/customer";
import { IMaskInput } from "react-imask";
import { debounce } from "@mui/material/utils";
import { cepAutoComplete } from "../../../services/cepService";


//código para aplicar máscara em Input
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

const TextMaskAdapter = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskAdapter(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			{...other}
			mask="00000-000"
			definitions={{
				"#": /[1-9]/,
			}}
			inputRef={ref}
			onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
			overwrite
		/>
	);
});

export default function AddressForm(props: {
	customerAddress: Customer;
	setCustomer: any;
	handleEdit: () => void;
	handleCancelOperation: () => void;
}) {
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [tempCep, setTempCep] = useState<string>(props.customerAddress?.Address?.Cep);

	async function handleCepApi(value, customer, setCustomer) {
		let cepResponse = await cepAutoComplete(value);
		customer.Address.State = cepResponse.state;
		customer.Address.City = cepResponse.city;
		customer.Address.Neighbourhood = cepResponse.neighborhood;
		customer.Address.Street = cepResponse.street;
		setCustomer({ ...customer });
	}

	const fetch = React.useMemo(
		() =>
			debounce(async (request, callback) => {
				if (request.input.length === 9) {
					handleCepApi(request.input, request.customerAddress, request.setCustomer);
				}
			}, 400),
		[]
	);

	React.useEffect(() => {
		fetch({ input: tempCep, customerAddress: props.customerAddress, setCustomer: props.setCustomer }, () => { });
	}, [tempCep, fetch]);

	useEffect(() => {
		props.customerAddress.Address.Cep = tempCep;
		props.setCustomer({ ...props.customerAddress });
	}, [tempCep]);

	return (
		<Grid
			container
			columnSpacing={4}
			rowSpacing={2}
			sx={{ backgroundColor: "var(--joy-palette-neutral-50)", borderRadius: "1rem" }}
		>
			<Grid md={12} xs={12}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Typography level="h4" sx={{ display: "flex", alignItems: "center", gap: "0.5em" }}>
						<Home />
						Endereço
					</Typography>
					<h3>Endereço</h3>
					<IconButton onClick={() => setIsEdit(!isEdit)} color="primary">
						<EditNote />
					</IconButton>
				</Box>
			</Grid>
			<Grid md={2} xs={8}>
				<FormControl>
					<FormLabel>CEP</FormLabel>
					<Input
						id="cep"
						color="primary"
						placeholder="CEP"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={tempCep}
						onChange={(evt) => setTempCep(evt.target.value)}
						slotProps={{ input: { component: TextMaskAdapter } }}
					/>
				</FormControl>
			</Grid>
			<Grid md={5} xs={12}>
				<FormControl>
					<FormLabel>Endereço</FormLabel>
					<Input
						color="primary"
						placeholder="Endereço"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={props.customerAddress?.Address?.Street}
						onChange={(evt) => {
							props.customerAddress.Address.Street = evt.target.value;
							props.setCustomer({ ...props.customerAddress });
						}}
					/>
				</FormControl>
			</Grid>
			<Grid md={2} xs={4}>
				<FormControl>
					<FormLabel>Número</FormLabel>
					<Input
						color="primary"
						placeholder="Número"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={props.customerAddress?.Address?.Number}
						onChange={(evt) => {
							props.customerAddress.Address.Number = evt.target.value;
							props.setCustomer({ ...props.customerAddress });
						}}
						startDecorator="Nº"
					/>
				</FormControl>
			</Grid>
			<Grid md={3} xs={12}>
				<FormControl>
					<FormLabel>Bairro</FormLabel>
					<Input
						color="primary"
						placeholder="Bairro"
						variant="outlined"
						disabled={isEdit ? false : true}
						value={props.customerAddress?.Address?.Neighbourhood}
						onChange={(evt) => {
							props.customerAddress.Address.Neighbourhood = evt.target.value;
							props.setCustomer({ ...props.customerAddress });
						}}
					/>
				</FormControl>
			</Grid>

			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>Cidade</FormLabel>
					<Input
						color="primary"
						placeholder="Cidade"
						variant="outlined"
						disabled={true}
						value={props.customerAddress?.Address?.City}
					/>
				</FormControl>
			</Grid>

			<Grid md={6} xs={12}>
				<FormControl>
					<FormLabel>Estado</FormLabel>
					<Input
						color="primary"
						placeholder="Estado"
						variant="outlined"
						disabled={true}
						value={props.customerAddress?.Address?.State}
					/>
				</FormControl>
			</Grid>

			{isEdit && (
				<>
					<Grid md={8} xs={8}></Grid>
					<Grid md={4} xs={4} sx={{ display: "flex", gap: "1em", justifyContent: "flex-end" }}>
						<Button
							color="neutral"
							onClick={() => {
								props.handleCancelOperation();
								setIsEdit(false);
							}}
						>
							Cancelar
						</Button>
						<Button
							onClick={() => {
								props.handleEdit();
								setIsEdit(false);
							}}
							color="secondary"
						>
							Salvar
						</Button>
					</Grid>
				</>
			)}
		</Grid>
	);
}
