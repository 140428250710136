import React, { useContext, useState } from "react";
import Element from "../../../../../interfaces/element";
import { ElementsContext } from "../../../../../pages/create/create";
import { v1 as uuidV1 } from "uuid";
import InputFileUpload from "../../../../global_components/InputFileUpload";
import { Stack } from "@mui/joy";
import UploadWizard from "../upload_wizard/upload_wizard";

export default function UploadMenu(props: { handleMenuLoading: (value: [boolean, string]) => void }) {
	const { elements, setElements } = useContext(ElementsContext);
	const [img, setImg] = useState<File | null>(null);

	const loadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files[0];
		if (file) {
			const url = URL.createObjectURL(file);
			console.log(url);
			setImg(file);
		}
	};

	const addPhotoToTexture = (fileRead: string) => {
		const newElement: Element = {
			id: uuidV1(),
			media: {
				src: fileRead,
			},
			x: 300,
			y: 300,
			type: "square",
			zIndex: elements.length + 1,
			scaleX: 0.3,
			scaleY: 0.3,
			shown: true,
			rotation: 0,
		};
		setElements([...elements, newElement]);
	};

	return (
		<div className="media">
			<Stack className="imgUpload" spacing={2}>
				<InputFileUpload onChange={loadPhoto} />
			</Stack>
			<UploadWizard image={img} />
		</div>
	);
}
