import ProfileForm from "../../../components/profile/general/profile";
import AddressForm from "../../../components/profile/address/address";
// import PaymentForm from "../../../components/profile/payment/payment";

import "./profile.scss";
import { fetchAuthSession } from "aws-amplify/auth";

import { Button, Divider, Grid, Skeleton, Snackbar, Stack, Typography } from "@mui/joy";
import { Helmet } from "react-helmet";
import PageHeader from "../../../components/global_components/PageHeader";
import { useEffect, useState } from "react";
import Customer from "../../../interfaces/customer";
import { getCustomerByID, editCustomer, deleteCustomer } from "../../../services/customerService";
import { ModalAlert, ModalForm } from "../../../components/global_components/Modal";
import { useNavigate } from "react-router-dom";

export default function Profile() {
	const nav = useNavigate();
	const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [snackbar, setSnackbar] = useState({ show: false, success: false });
	const [customer, setCustomer] = useState<Customer>({
		Address: {
			Cep: "",
			City: "",
			Neighbourhood: "",
			Number: "",
			State: "",
			Street: "",
		},
		Email: "",
		Id: "",
		Name: "",
		Password: "",
		Phone: "",
		Other: "",
	});

	const handleAPI = async () => {
		const info = await fetchAuthSession();
		setLoading(true);
		let customerAPI = await getCustomerByID(info.userSub);
		setLoading(false);
		setCustomer(customerAPI);
	};
	useEffect(() => {
		handleAPI();
	}, []);

	async function handleEditCustomer(customerId: Customer["Id"], customer: Customer) {
		editCustomer(customerId, customer)
			.then(() => {
				setSnackbar({ show: true, success: true });
			})
			.catch(() => {
				setSnackbar({ show: true, success: false });
			});
	}

	async function handleDeleteCustomer(customerId: Customer["Id"]) {
		deleteCustomer(customerId).then(() => nav('/')).catch(() => alert("erro!"))
	}

	return (
		<>
			<Stack
				spacing={2}
				padding={{ md: "2em", xs: "0.5em 0.5em" }}
				divider={<Divider sx={{ margin: "1em 0" }} />}
				maxWidth={1300}
				width="100vw"
			>
				<Helmet>
					<title>Fizz | Perfil</title>
				</Helmet>
				<PageHeader title="Meu Perfil" />
				{loading && (
					<>
						<Grid
							container
							columnSpacing={4}
							rowSpacing={2}
							sx={{ backgroundColor: "var(--joy-palette-neutral-50)", borderRadius: "1rem" }}
						>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={"100%"}
								height={"300px"}
								sx={{ borderRadius: "1rem" }}
							/>
						</Grid>

						<Grid
							container
							columnSpacing={4}
							rowSpacing={2}
							sx={{ backgroundColor: "var(--joy-palette-neutral-50)", borderRadius: "1rem" }}
						>
							<Skeleton
								animation="wave"
								variant="rectangular"
								width={"100%"}
								height={"300px"}
								sx={{ borderRadius: "1rem" }}
							/>
						</Grid>
					</>
				)}
				{loading !== true && (
					<>
						<ProfileForm
							customerInfo={customer}
							handleEdit={() => {
								setConfirmationModal(true);
							}}
							handleCancelOperation={handleAPI}
							setCustomer={setCustomer}
						/>

						<AddressForm
							customerAddress={customer}
							setCustomer={setCustomer}
							handleEdit={() => {
								setConfirmationModal(true);
							}}
							handleCancelOperation={handleAPI}
						/>

						{/* <PaymentForm /> */}

						<div>
							<Button color="danger" onClick={() => setDeleteModal(true)} size="md">
								Excluir Perfil
							</Button>
						</div>
					</>
				)}
			</Stack>
			<ModalAlert
				openModal={confirmationModal}
				title="Deseja aplicar as alterações?"
				confirmText="Salvar"
				confirmTextColor="success"
				handleCloseModal={() => setConfirmationModal(false)}
				handleCancelOperation={() => setConfirmationModal(false)}
				handleConfirmation={() => {
					handleEditCustomer(customer.Id, customer);
					setConfirmationModal(false);
				}}
			/>
			<Snackbar
				open={snackbar.show}
				autoHideDuration={3000}
				variant="solid"
				color={snackbar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackbar.show = false;
					setSnackbar({ ...snackbar });
				}}
			>
				{snackbar.success ? "As atualizações de perfil foram salvas com sucesso!" : "Erro ao tentar salvar alterações!"}
			</Snackbar>

			<ModalForm
				openModal={deleteModal}
				modalTitle="Tem certeza que deseja excluir seu perfil?"
				confirmText="Exluir"
				confirmTextColor="danger"
				handleCancelOperation={() => setDeleteModal(false)}
				handleCloseModal={() => setDeleteModal(false)}
				handleConfirmation={() => handleDeleteCustomer(customer.Id)}
			>
				<Typography>
					Ao excluir seu perfil perderá todos seus projetos!
				</Typography>
				<Typography>
					Não há como recuperar seu perfil após confirmar!
				</Typography>
			</ModalForm>
		</>
	);
}
