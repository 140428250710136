import "./create.scss";

import { newProject, getProjects, editProject, getprojectByID } from "../../services/projectService";

import ObjectMenu from "../../components/create/object_menu/object_menu";
import ObjectMenuDesktop from "../../components/create/object_menu/object_menu_desktop";
import MugTopLayout from "../../layout/mugTopLayout";
import { Helmet } from "react-helmet";
import collectionCard from "../../interfaces/collectionCard";
// import { useCart } from '../../router/cartProducts';

import Element from "../../interfaces/element";

import { Box, CircularProgress, FormControl, FormLabel, Input, Snackbar, Stack, Textarea } from "@mui/joy";

import React, { createContext, useState, useEffect, lazy, Suspense, useRef } from "react";
import { ModalAlert, ModalForm, ModalLoading } from "../../components/global_components/Modal";
import { usePreviousRoute } from "../../router/RouterTracker";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../router/UserInfo";
import { useCart } from "../../router/cartProducts";

const Motor = lazy(() => import("../../components/create/motor/motor"));

export const ElementsContext = createContext<{
	elements: Element[];
	setElements: React.Dispatch<React.SetStateAction<Element[]>>;
}>(null);

export const ProjectContext = createContext<{
	project: collectionCard;
	setProject: React.Dispatch<React.SetStateAction<collectionCard>>;
}>(null);

export default function Create() {
	//HOOKS
	const previousRoute = usePreviousRoute();
	const user = useUser();
	const nav = useNavigate();
	const { projectID } = useParams();
	const cart = useCart();

	const motorRef = useRef<any>()

	//STATE HOOK
	const [elements, setElements] = useState<Element[]>([]);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
	const [isMenuLoading, setIsMenuLoading] = useState<[boolean, string]>([false, "Loading File"]);
	const [tutorialIndex, setTutorialIndex] = useState(null);
	const [modal, setModal] = useState<boolean>(false);
	const [modalAlert, setModalAlert] = useState<boolean>(false);
	const [prevRoute, setPrevRoute] = useState();
	const [snackBar, setSnackBar] = useState({ show: false, success: false });
	const [background, setBackground] = useState();
	const [project, setProject] = useState<collectionCard>({
		Title: "",
		Description: "",
	});
	const [tempTitle, setTempTitle] = useState(project?.Title ?? "");
	const [tempDescription, setTempDescription] = useState(project?.Description ?? "");

	//FUNCTIONS
	function handleTutorial(value: number | null) {
		if (value) {
			setTutorialIndex(tutorialIndex + value);
		} else {
			setTutorialIndex(null);
			setModal(true);
		}
	}

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	async function loadProjectFromAPI(projectId: string) {
		setIsProjectLoading(true);
		getprojectByID(projectId)
			.then((x) => {
				console.log("x.Texts: ", x.Texts);
				let loadElements = x.Texts.map((y: string) => JSON.parse(y) as Element);
				setElements(loadElements);
				setProject(x);
			})
			.catch((x) => {
				console.error(x);
			})
			.finally(() => {
				setIsProjectLoading(false);
			});
	}

	function handleSaveProject() {
		if (project.Id && project.Id !== "offline") {
			return handleEditProject();
		}
		const sendData = {
			Title: project.Title,
			Description: project.Description,
			MediaIds: elements.filter((x) => x.media).map((media) => media.id),
			Texts: elements.map((x) => JSON.stringify(x)),
		};
		newProject(sendData)
			.then((x) => {
				project.Id = x.Id;
				setProject({ ...project });
				console.log(x);
				setSnackBar({
					show: true,
					success: true,
				});
				nav(`/criar/${project.Id}`, { replace: true });
			})
			.catch(() =>
				setSnackBar({
					show: true,
					success: false,
				})
			);
	}

	function handleDownload(){
		motorRef.current.handleDownload()
	}

	function handleEditProject(toCart: boolean = false) {
		const sendData = {
			Title: project.Title,
			Description: project.Description,
			MediaIds: elements.filter((x) => x.media).map((media) => media.id),
			Texts: elements.map((x) => JSON.stringify(x)),
		};
		editProject(project.Id, sendData)
			.then((x) => {
				if (x?.Id != null && x.Id !== projectID) {
					nav(`/criar/${x?.Id}`, { replace: true });
				}
				if (toCart) {
					setModalAlert(true);
				}
				setSnackBar({ show: true, success: true });
			})
			.catch(() => setSnackBar({ show: true, success: false }));
	}

	function handleRoute() {
		if (prevRoute !== "/login" && prevRoute !== undefined) {
			nav(-1);
		} else {
			nav("/");
		}
	}

	//USE EFFECTS
	useEffect(() => {
		handleResize();
		setTutorialIndex(null);
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (projectID) {
			loadProjectFromAPI(projectID);
		}
	}, [projectID]);

	useEffect(() => {
		const handleAPI = async () => {
			let projects = await getProjects();
			if (projects?.length === 0) {
				setTutorialIndex(0);
			} else {
				if (!projectID) {
					setModal(true);
					let newProject = { Title: "", Description: "", Id: undefined };
					setProject(newProject);
				}
			}
		};
		handleAPI();
	}, [projectID, user.user]);

	useEffect(() => {
		setPrevRoute(previousRoute?.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//FUNCTIONS TO SET ELEMENTS ON PAGE
	function handleTopLayout() {
		return <MugTopLayout width={isMobile ? "100vw" : "70vw"} />;
	}

	function handleContextMenu() {
		if (isMobile) {
			return (
				<Box className="contextMenu">
					<ObjectMenu
						bgImage={setBackground}
						index={tutorialIndex}
						handleStep={handleTutorial}
						mugTitle={project?.Title}
						mugDescription={project?.Description}
						handleEdit={() => setModal(true)}
						handleSave={handleEditProject}
						handleMenuLoading={setIsMenuLoading}
					/>
				</Box>
			);
		}
		if (!isMobile) {
			return (
				<Box sx={{ width: "30vw", height: "100%", borderLeft: "solid 1px var(--outline)" }}>
					<ObjectMenuDesktop
						bgImage={setBackground}
						mugTitle={project?.Title}
						mugDescription={project?.Description}
						projectId={project?.Id}
						index={tutorialIndex}
						handleStep={handleTutorial}
						handleEditTitle={() => setModal(true)}
						handleSaveProject={handleEditProject}
						handleDownloadProject={handleDownload}
						handleMenuLoading={setIsMenuLoading}
					/>
				</Box>
			);
		}
	}

	//page view html
	return (
		<Stack className="creator" direction="row" flexWrap="wrap">
			<Helmet>
				<title>Fizz | Editor</title>
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
			</Helmet>
			<ProjectContext.Provider value={{ project, setProject }}>
			<ElementsContext.Provider value={{ elements, setElements }}>
				{handleTopLayout()}
				<div className="motor">
					<Suspense
						fallback={
							<>
								<CircularProgress sx={isMobile ? {} : { width: "70vw" }} />
								<ModalLoading openModal={true} modalTitle="Carregando editor" />
							</>
						}
					>
						<Motor index={tutorialIndex} handleStep={handleTutorial} mugBackgroundImage={background} ref={motorRef}/>
					</Suspense>
				</div>
				{handleContextMenu()}
			</ElementsContext.Provider>
			</ProjectContext.Provider>

			<ModalForm
				openModal={modal}
				modalSize="lg"
				modalTitle="Personalizar"
				confirmText={project?.Id != null ? "Salvar" : "Começar"}
				confirmTextColor="primary"
				handleCloseModal={() => setModal(false)}
				handleCancelOperation={() =>
					project?.Id != null
						? (setModal(false), setTempTitle(project?.Title), setTempDescription(project?.Description))
						: handleRoute()
				}
				handleConfirmation={() => {
					project.Title = tempTitle;
					project.Description = tempDescription;
					setProject({ ...project });
					handleSaveProject();
					setModal(false);
				}}
			>
				<FormControl sx={{ marginBottom: "1em" }}>
					<FormLabel>{"Nome do projeto"}</FormLabel>
					<Input
						value={tempTitle}
						placeholder={"Minha Caneca"}
						onChange={(evt: any) => {
							setTempTitle(evt.target.value);
						}}
					></Input>
				</FormControl>
				<FormControl>
					<FormLabel>{"Dê uma descrição ao seu projeto. (Opcional)"}</FormLabel>
					<Textarea
						placeholder={"Descrição do projeto"}
						sx={{ width: "100%" }}
						maxRows={5}
						minRows={3}
						value={tempDescription}
						onChange={(evt) => {
							setTempDescription(evt.target.value);
						}}
					/>
				</FormControl>
			</ModalForm>

			<ModalLoading
				modalTitle={isMenuLoading[0] ? isMenuLoading[1] : "Carregando projeto"}
				openModal={isProjectLoading || isMenuLoading[0]}
			></ModalLoading>

			<ModalAlert
				openModal={modalAlert}
				title="Finalizar compra"
				content="deseja seguir para carrinho e finalizar a edição?"
				confirmText="Finalizar"
				confirmTextColor="success"
				handleConfirmation={() => {
					cart.addToCart(project);
					let localCartProject: any = project
					sessionStorage.setItem("cartItem", JSON.stringify(localCartProject))
					nav("/carrinho");
				}}
				handleCancelOperation={() => setModalAlert(false)}
				handleCloseModal={() => setModalAlert(false)}
			/>

			<Snackbar
				autoHideDuration={3000}
				open={snackBar.show}
				variant="solid"
				color={snackBar.success ? "success" : "danger"}
				onClose={(event, reason) => {
					if (reason === "clickaway") {
						return;
					}
					snackBar.show = false;
					setSnackBar({ ...snackBar });
				}}
			>
				{snackBar.success ? `O projeto "${project.Title}" foi salvo com sucesso!` : "Erro ao salvar projeto"}
			</Snackbar>
		</Stack>
	);
}
