import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { View, Text, Heading, Button, useAuthenticator, useTheme } from '@aws-amplify/ui-react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { I18n } from 'aws-amplify/utils';

import './login.scss';

Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: '65nqr1kefttg7384n5d1dvt72e',
        userPoolId: 'sa-east-1_J1uBLQ2JE',
        loginWith: { // Optional
          oauth: {
            domain: 'fizzonline.auth.sa-east-1.amazoncognito.com',
            scopes: ['jdb-public-api/user', 'aws.cognito.signin.user.admin'],
            redirectSignIn: ['https://www.fizzonline.com.br/minha-area', 'http://localhost:3000/minha-area'],
            redirectSignOut: ['https://www.fizzonline.com.br/','http://localhost:3000/'],
            responseType: 'code',
          },
          username: true,
        }
      }
    }
  });

  I18n.setLanguage('pt');
  const dict = {
    pt: {
      'Sign In': 'Entrar',
      'Sign in': 'Entrar',
      'Sign Up': 'Registrar-se',
      'Create Account': 'Criar Conta',
      'Sign In with Google': 'Entrar com o Google',
      'Sign Up with Google': 'Entrar com o Google',
      'or': 'ou',
    }
  };
  
  I18n.putVocabularies(dict);

const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
        </View>
      );
    },
  
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy; Todos os direitos reservados
          </Text>
        </View>
      );
    },
  
    SignIn: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            
          </Heading>
        );
      },
      Footer() {
        const { toForgotPassword } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toForgotPassword}
              size="small"
              variation="link"
            >
              Esqueceu a senha?
            </Button>
          </View>
        );
      },
    },
  
    SignUp: {
      Header() {
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
          </Heading>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Voltar para o login
            </Button>
          </View>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
    SetupTotp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ForgotPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Inserir informações:
          </Heading>
        );
      },
      Footer() {
        return <Text></Text>;
      },
    },
  };
  
const formFields = {
    signIn: {
      username: {
        label: "Email",
        placeholder: 'Inserir seu email',
      },
      password: {
        label: 'Senha',
        placeholder: 'Inserir sua senha',
      },
    },
    signUp: {
      username:{
        label: "Email",
        placeholder: 'Inserir seu email',
        order: 1,
      },
      password: {
        label: 'Senha',
        placeholder: 'Inserir sua senha',
        isRequired: false,
        order: 2,
      },
      confirm_password: {
        label: 'Confirmação de senha',
        placeholder: 'Inserir sua senha novamente',
        order: 3,
      },
    },
    forceNewPassword: {
      password: {
        placeholder: 'Inserir sua senha',
      },
    },
    forgotPassword: {
      username: {
        placeholder: 'Inserir seu email',
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: 'Inserir código de confirmação',
        label: 'Código de confirmação',
        isRequired: false,
      },
      confirm_password: {
        placeholder: 'Inserir sua senha',
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: 'test issuer',
        totpUsername: 'amplify_qr_test_user',
      },
      confirmation_code: {
        label: 'Código de confirmação',
        placeholder: 'Inserir seu código de confirmação',
        isRequired: false,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: 'Código de confirmação',
        placeholder: 'Enter your Confirmation Code',
        isRequired: false,
      },
    },
  };


export default function Login({ signOut, user }: WithAuthenticatorProps) {
  return (
    <Authenticator formFields={formFields} components={components} socialProviders={['google']}>
      {({ signOut }) => <button onClick={signOut}>Sign out</button>}
    </Authenticator>
  );
}
