import { BrowserRouter, Route, Routes } from 'react-router-dom';


import Landing from './pages/landing/landing';
import NotFound from './pages/error/NotFound';
import Create from './pages/create/create';
import Collection from './pages/user/collection/collection';
import Profile from './pages/user/profile/profile';
import Login from './pages/login/loginAmplify';
import Test from './pages/test/test';
import TopLayout from './layout/topLayout'
import Cart from './pages/user/cart/cart';


import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import extendedTheme from './styles/extendedTheme';

import { PreviousRouteProvider } from './router/RouterTracker';
import { UserProvider } from './router/UserInfo';
import { CartProductsProvider } from './router/cartProducts';

import { useState } from 'react';
import UserArea from './pages/user/area/area';

function App() {
  const [, setIsEdit] = useState(null)

  return (
    <CssVarsProvider theme={extendedTheme}>
      <CssBaseline>
        <BrowserRouter>
          <UserProvider>
            <PreviousRouteProvider>
              <CartProductsProvider>
                <Routes>
                  <Route path='/' element={<TopLayout />}>
                    <Route index element={<Landing />} />
                    <Route path='criar/:projectID?' element={<Create />} />
                    <Route path='explorar' element={<NotFound />} />
                    <Route path='parceria' element={<NotFound />} />
                    <Route path='login' element={<Login />} />
                    <Route path='test' element={<Test />} />
                    <Route path='*' element={<NotFound />} />
                    <Route path='colecao/:userName' element={<Collection toEdit={setIsEdit} />} />
                    <Route path='carrinho' element={<Cart />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path='minha-area' element={<UserArea toEdit={setIsEdit} />} />
                  </Route>
                </Routes>
              </CartProductsProvider>
            </PreviousRouteProvider>
          </UserProvider>
        </BrowserRouter>
      </CssBaseline>
    </CssVarsProvider>
  );
}

export default App;
