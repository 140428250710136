import secVarBlob from "../../assets/secVarBlob.svg";
import secBlob from "../../assets/secBlob.svg";
import primBlob from "../../assets/primBlob.svg";
import primBlob2 from "../../assets/primBlob2.svg";

import './PageHeader.scss';

import { Box, Typography } from "@mui/joy";


export default function PageHeader(props: {
    title: string
}) {
    return (
        <Box id="collectionBoard">
            <div className="blobBox">
                <img src={secVarBlob} className="blob" alt="blob1"></img>
                <img src={secBlob} className="blob" alt="blob2"></img>
                <img src={primBlob} className="blob" alt="blob3"></img>
                <img src={primBlob2} className="blob" alt="blob4"></img>
            </div>
            <Typography level="h1" className="title">{props.title}</Typography>

        </Box>
    )
}