import { KeyboardArrowDown } from "@mui/icons-material";
import { Select, Option, selectClasses } from "@mui/joy";
import { fontData } from "./fontData";
import { useEffect, useState } from "react";

async function loadFonts(fontList: string[]) {
    for(let i=0; i<fontList.length; i++){
        const path = "/fonts/"+fontList[i]
        const font = new FontFace(fontList[i].split(".")[0].replace(" ", ""), `url("${path}") format("opentype")`, {
            style: "normal",
        });
    
        // wait for font to be loaded
        await font.load();
        // add font to document
        document.fonts.add(font);
    }

    return true
}

export default function FontSelector(props: { onChange: (evt, value) => void; value: string}) {
    const [loaded, setLoaded] = useState([])

    useEffect( ()=>{
        loadFonts(fontData).then(status=>{
            let fonts = fontData.map(x=>x.split(".")[0].replace(" ", ""))
            setLoaded([...fonts])
        })
    }, [])
	return (
		<Select
			placeholder="Escolha sua fonte"
			value={props.value}
			onChange={props.onChange}
			style={{ width: "100%" }}
			indicator={<KeyboardArrowDown />}
			sx={{
				width: 240,
				[`& .${selectClasses.indicator}`]: {
					transition: "0.2s",
					[`&.${selectClasses.expanded}`]: {
						transform: "rotate(-180deg)",
					},
				},
			}}
		>
			<Option value="Roboto">
				<span style={{ fontSize: 25, fontFamily: "Roboto" }}>Roboto</span>
			</Option>
			<Option value="Bad Script">
				<span style={{ fontSize: 25, fontFamily: "Bad Script" }}>Bad Script</span>
			</Option>
			<Option value="Lilita One">
				<span style={{ fontSize: 25, fontFamily: "Lilita One" }}>Lilita One</span>
			</Option>
			{loaded.map((x, i) => {
				return (
					<Option value={x} key={i}>
						<span style={{ fontSize: 25, fontFamily: x }}>{x}</span>
					</Option>
				);
			})}
		</Select>
	);
}
