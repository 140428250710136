import "./StepBody.scss";
import { useNavigate } from "react-router-dom";

import { Box, Button, Divider, Grid, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useUser } from "../../router/UserInfo";

export default function StepBody(props: {
	totalValue?: number;
	step: number;
	children: any;
	handleStepper: (value: number | null) => void;
	handleFinalization?: () => void;
}) {
	const nav = useNavigate();
	const user = useUser();
	const [total, ] = useState<number>(props.totalValue ?? 0);
    const [isMobile, setIsMobile] = useState<boolean>(false);


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768 ? true : false);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

	return (
		<Grid className="body" container>
			<Grid container columnSpacing={4} rowSpacing={2}>
				<Grid container md={8} xs={12}>
					{props.children}
				</Grid>
				<Grid container md={4} xs={12}>
					<Grid md={1} xs={12} container justifyContent="center">
						<Divider orientation={isMobile ? 'horizontal' : 'vertical'} sx={isMobile ? { width: "100%", margin: "1em 0" } : { height: "100%", marginLeft: "2em" }} />
					</Grid>
					<Grid md={11} xs={12}>
						<Box className="buttonWrapper">
							{props.step !== 1 && props.step !== 2 && (
								<Box>
									<Typography level="title-lg">Total:</Typography>
									<Typography>R$ {total.toFixed(2)}</Typography>
									<Divider sx={{ margin: "1em 0" }} />
									<Typography level="body-sm" sx={{ marginBottom: "1em" }}>
										{
											"*O valor acima refere-se ao valor da(s) caneca(s). Seu atendimento será continuado através do e-mail ou telefone que você disponibilizar, e, por lá será informado o valor do frete."
										}
									</Typography>
								</Box>
							)}
							{user.user && (
								<>
									<Button
										onClick={() => {
											if (props.step !== 3) {
												props.handleStepper(+1);
											} else {
												props.handleFinalization();
											}
										}}
										color="primary"
									>
										{props.step !== 3 ? "Avançar" : "Finalizar"}
									</Button>
									<Button
										color="secondary"
										onClick={() => {
											if (props.step !== 0) {
												props.handleStepper(-1);
											} else {
												nav("/colecao");
											}
										}}
									>
										{props.step !== 0 ? "Voltar" : "Continuar Comprando"}
									</Button>
								</>
							)}
							{user.user == null && (
								<Button
									onClick={() => {
										nav("/login");
										let cartRoute = "carrinho";
										sessionStorage.setItem("carrinho", cartRoute);
									}}
									color="primary"
								>
									Fazer Login
								</Button>
							)}
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
