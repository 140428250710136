export const mockData = [
  {
    "createdAt": "2024-04-29T21:41:10.136Z",
    "title": "title 1",
    "favorite": true,
    "id": "1",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T09:30:35.963Z",
    "title": "title 2",
    "favorite": false,
    "id": "2",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T20:08:29.742Z",
    "title": "title 3",
    "favorite": true,
    "id": "3",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T22:34:07.232Z",
    "title": "title 4",
    "favorite": true,
    "id": "4",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2023-04-29T23:07:35.846Z",
    "title": "OLDEST",
    "favorite": false,
    "id": "5",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T21:41:10.136Z",
    "title": "title 1",
    "favorite": true,
    "id": "6",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T09:30:35.963Z",
    "title": "title 2",
    "favorite": false,
    "id": "7",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T20:08:29.742Z",
    "title": "title 3",
    "favorite": true,
    "id": "8",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-30T22:34:07.232Z",
    "title": "NEWEST",
    "favorite": true,
    "id": "9",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  },
  {
    "createdAt": "2024-04-29T23:07:35.846Z",
    "title": "title 5",
    "favorite": false,
    "id": "10",
    "description": "pequena descrição da caneca!!",
    "quantity": 1,
    "price": 12.00,
    "model": "320ml Branca"
  }
]