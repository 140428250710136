import React, { createContext, useContext, useState } from 'react';

const CartProductsContext = createContext(null);

export function CartProductsProvider({ children }) {
  const [productList, setProductList] = useState([]);


  function addToCart(item) {
    console.log("chegou")
    if(productList.map((x) => x.Id).includes(item.Id)) {
      const newList = productList.map((obj) => {
        if(obj.Title === item.Title) {
          obj.quantity ++;

          return obj;
        }
        return obj;
      })
      setProductList([...newList])
    } else {
      setProductList((prevProductList) => [
        ...prevProductList,
        item
      ]);
    }
  }

  return (
    <CartProductsContext.Provider value={{ productList, setProductList, addToCart }}>
      {children}
    </CartProductsContext.Provider>
  );
}

export function useCart() {
  return useContext(CartProductsContext);
}