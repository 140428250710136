import { extendTheme } from "@mui/joy/styles";

declare module '@mui/joy/Button' {
  interface ButtonPropsSizeOverrides {
    xs: true;
    xl: true;
  }
}

export default extendTheme({
  fontFamily: {
    display: '"Roboto", var(--joy-fontFamily-fallback)',
    body: '"Roboto", var(--joy-fontFamily-fallback)',
  },
  fontSize: {
    xl4: '3rem',
    xl3: '2rem',
    xl2: '1.875rem',
    xl: '1.5rem',
    lg: '1.25rem',
    md: '1.125rem',
    sm: '1rem',
    xs: '0.875rem'
  },
  components: {
    JoyAccordionSummary:{
      styleOverrides:{
        root:{
          paddingInlineEnd: "calc(var(--ListItem-paddingRight)-4px)"
        }
      }
    },
    JoyAccordionDetails: {
      styleOverrides: {
        root: {
          paddingInlineStart: "0px",
          paddingInlineEnd: "0px",
          paddingBlockStart: "0.25rem"
        }
      }
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === "xs" && {
            "--Icon-fontSize": "1rem",
            "--Button-gap": "0.25rem",
            minHeight: "var(--Button-minHeight, 1.75rem)",
            fontSize: theme.vars.fontSize.xs,
            paddingBlock: "2px",
            paddingInline: "0.5rem",
          }),
          ...(ownerState.size === "xl" && {
            "--Icon-fontSize": "2rem",
            "--Button-gap": "1rem",
            minHeight: "var(--Button-minHeight, 4rem)",
            fontSize: theme.vars.fontSize.xl,
            paddingBlock: "0.5rem",
            paddingInline: "2rem",
          }),
        }),
      },
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#D3D5E5",
          100: "#B2B0CF",
          200: "#9B8DB9",
          300: "#886BA3",
          400: "#7B4A8C",
          500: "#722974",
          600: "#67235A",
          700: "#591D41",
          800: "#4B172B",
          900: "#3D1219",
        },
        secondary: {
          50: "#FFDFCC",
          100: "#FFC7A3",
          200: "#FFB07A",
          300: "#FF9952",
          400: "#FF8429",
          500: "#F86F03",
          600: "#EC6300",
          700: "#D95800",
          800: "#C64E00",
          900: "#B34400",
          solidColor: "var(--joy-palette-secondary-50)",
          solidBg: "var(--joy-palette-secondary-500)",
          solidActiveBg: "var(--joy-palette-secondary-700)",
          solidHoverBg: "var(--joy-palette-secondary-600)",
          solidDisabledBg: "var(--joy-palette-neutral-300)",
          solidDisabledColor: "var(--joy-palette-neutral-400)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          outlinedHoverBg: "var(--joy-palette-secondary-50)",
          outlinedDisabledBorder: "var(--joy-palette-neutral-200)",
          outlinedDisabledColor: "var(--joy-palette-neutral-400)",
          softColor: "var(--joy-palette-secondary-800)",
          softBg: "var(--joy-palette-secondary-200)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          softHoverBg: "var(--joy-palette-secondary-500)",
          softDisabledBg: "var(--joy-palette-neutral-300)",
          softDisabledColor: "var(--joy-palette-neutral-400)",
          plainColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
          plainHoverBg: "var(--joy-palette-secondary-300)",
          plainDisabledColor: "var(--joy-palette-neutral-400)",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          50: "#D3D5E5",
          100: "#B2B0CF",
          200: "#9B8DB9",
          300: "#886BA3",
          400: "#7B4A8C",
          500: "#722974",
          600: "#67235A",
          700: "#591D41",
          800: "#4B172B",
          900: "#3D1219",
        },
        secondary: {
          50: "#FFDFCC",
          100: "#FFC7A3",
          200: "#FFB07A",
          300: "#FF9952",
          400: "#FF8429",
          500: "#F86F03",
          600: "#EC6300",
          700: "#D95800",
          800: "#C64E00",
          900: "#B34400",
          solidColor: "var(--joy-palette-secondary-50)",
          solidBg: "var(--joy-palette-secondary-400)",
          solidActiveBg: "var(--joy-palette-secondary-700)",
          solidHoverBg: "var(--joy-palette-secondary-500)",
          solidDisabledBg: "var(--joy-palette-neutral-300)",
          solidDisabledColor: "var(--joy-palette-neutral-400)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          outlinedHoverBg: "var(--joy-palette-secondary-500)",
          outlinedDisabledBorder: "var(--joy-palette-neutral-200)",
          outlinedDisabledColor: "var(--joy-palette-neutral-400)",
          softColor: "var(--joy-palette-secondary-800)",
          softBg: "var(--joy-palette-secondary-200)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          softHoverBg: "var(--joy-palette-secondary-500)",
          softDisabledBg: "var(--joy-palette-neutral-300)",
          softDisabledColor: "var(--joy-palette-neutral-400)",
          plainColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
          plainHoverBg: "var(--joy-palette-secondary-300)",
          plainDisabledColor: "var(--joy-palette-neutral-400)",
        },
      },
    },
  },
});
