import ImageMenu from "./img_menu/img_menu";
import TextMenu from "./text_menu/text_menu";
import EmojiMenu from "./emoji_menu/emoji_menu";
import StickerMenu from "./sticker_menu/sticker_menu";

import "./object_menu_desktop.scss";

import { Stack, Typography, Divider, Box, Button, IconButton, Tabs, Tab, TabList, TabPanel, Tooltip } from "@mui/joy";

import { AddAPhoto, AddReaction, AddShoppingCart, Download, Edit, Save, TextFields } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Tutorial from "../../global_components/tutorial";
import BackgroundMenu from "./img_menu/background_menu/background_menu";

export default function ObjectMenuDesktop(props: {
	mugTitle?: string;
	mugDescription?: string;
	bgImage?: any;
	index?: number | null;
	projectId: string;
	handleStep: (value: number | null) => void;
	handleEditTitle: () => void;
	handleSaveProject: (toCart: boolean) => void;
	handleDownloadProject: () => void;
	handleMenuLoading: (value: [boolean, string]) => void;
}) {
	const [title, setTitle] = useState(props.mugTitle ?? "Minha Caneca");
	const [, setDescription] = useState(props.mugDescription ?? "");

	useEffect(() => {
		setTitle(props.mugTitle);
		setDescription(props.mugDescription);
	}, [props]);

	return (
		<Stack sx={{ height: "100vh", maxHeight: "100vh" }}>
			<Tooltip title="Editar título" variant="outlined" color="primary">
				<Box
					sx={{ display: "flex", justifyContent: "center", height: "8vh", alignItems: "center" }}
					onClick={() => props.handleEditTitle()}
				>
					<Typography level="h3" sx={{ textAlign: "center" }}>
						{title || "Nomeie sua caneca"}
					</Typography>
				</Box>
			</Tooltip>

			<Divider />

			<Tabs aria-label="Basic tabs" sx={{ height: "80vh" }} defaultValue={0}>
				<Tutorial
					tutorialText={
						"Aqui você pode navegar entre as abas do editor para personalizar sua caneca!\nNa aba 'Texto', por exemplo, você pode adicionar qualquer texto que escrever, assim como selecionar a fonte e cor do seu texto. "
					}
					open={props.index === 0}
					handleSteps={props.handleStep}
					showPreviousBtn={false}
					complete={25}
				>
					<TabList
						id="tabList"
						sx={{
							display: "flex",
							width: "100%",
						}}
						disableUnderline
					>
						<Tab color="primary" sx={{ flexGrow: 1, textOverflow: "ellipsis" }}>
							<AddAPhoto />
							Imagem
						</Tab>
						<Tab color="primary" sx={{ flexGrow: 1, textOverflow: "ellipsis" }}>
							<TextFields />
							Texto
						</Tab>
						<Tab color="primary" sx={{ flexGrow: 1, textOverflow: "ellipsis" }}>
							<AddReaction />
							Emoji
						</Tab>
						<Tab color="primary" sx={{ flexGrow: 1, textOverflow: "ellipsis" }}>
							<span className="material-symbols-rounded">note_stack_add</span>
							Figurinhas
						</Tab>
					</TabList>
				</Tutorial>
				<TabPanel value={0} style={{ maxHeight: "100%" }}>
					<ImageMenu handleMenuLoading={props.handleMenuLoading} />
				</TabPanel>
				<TabPanel value={1} style={{ maxHeight: "100%" }}>
					<TextMenu />
				</TabPanel>
				<TabPanel value={2}>
					<EmojiMenu />
				</TabPanel>
				<TabPanel value={3} style={{ maxHeight: "100%" }}>
					<StickerMenu />
				</TabPanel>
			</Tabs>

			<Divider />

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: "white",
					justifyContent: "space-evenly",
					alignContent: "center",
					width: "30vw",
					height: "10vh",
				}}
			>
				<Tutorial
					tutorialText={
						"Quando estiver satisfeito com sua criação, pode escolher entre salvar em sua coleção ou adicioná-la direto ao carrinho."
					}
					open={props.index === 3}
					handleSteps={props.handleStep}
					showNextBtn={false}
					complete={100}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							padding: "1rem 0",
						}}
					>
						<Button
							variant="outlined"
							sx={{ width: "25%" }}
							color="secondary"
							onClick={() => props.handleSaveProject(false)}
						>
							<Save />
							Salvar
						</Button>
						<Button
							variant="outlined"
							sx={{ width: "25%" }}
							color="secondary"
							onClick={() => props.handleDownloadProject()}
						>
							<Download />
							Baixar
						</Button>
						<Button variant="outlined" sx={{ width: "35%" }} onClick={() => props.handleSaveProject(true)}>
							<AddShoppingCart />
							Adicionar ao Carrinho
						</Button>
					</Box>
				</Tutorial>
			</Box>
		</Stack>
	);
}
