import './cart.scss'
import { Stack, Box, Button, Typography, Step, StepIndicator, Stepper, StepButton, Grid } from "@mui/joy";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCart } from "../../../router/cartProducts";

import { Helmet } from 'react-helmet';
import PageHeader from "../../../components/global_components/PageHeader";

import CartStep from '../../../components/purchase/cart/CartStep'
import { Call, Check, LocalPostOffice, LocalShipping, ShoppingCart } from '@mui/icons-material';
import AddressStep from '../../../components/purchase/address/AddressStep';
import FinalStep from '../../../components/purchase/final/FinalStep';
import ContactStep from '../../../components/purchase/contact/ContactStep';
import { useUser } from '../../../router/UserInfo';

export default function Cart() {
    const nav = useNavigate();
    const product = useCart();
    const user = useUser();
    const [shop, setShop] = useState(product.productList)
    const [activeStep, setActiveStep] = useState(0);
    const [isMobile, setIsMobile] = useState<boolean>(false);


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768 ? true : false);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setShop(product.productList)
    }, [product.productList])

    function handleStepper(value: number | null) {
        if (value) {
            setActiveStep(activeStep + value)
        }
    }

    return (
        <Stack spacing={2} padding={{ md: "2em", xs: "0.5em 0.5em" }} maxWidth={1300} width="100vw">
            <Helmet>
                <title>Fizz | Carrinho</title>
            </Helmet>
            <PageHeader title="Meu Carrinho" />
            <Grid container>
                {
                    shop?.length > 0 &&
                    <Grid container md={12} xs={12}>
                        <Grid md={12} xs={12}>
                            <Stepper size='lg' sx={{ marginBottom: "2em !important", height: "100%" }}>
                                <Step
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    indicator={
                                        <StepIndicator
                                            variant="solid"
                                            color={activeStep < 0 ? 'neutral' : 'primary'}>
                                            {
                                                activeStep <= 0 ? <ShoppingCart /> : <Check />
                                            }
                                        </StepIndicator>
                                    }
                                    sx={{
                                        '&::after': {
                                            ...(activeStep !== 0 && { bgcolor: 'primary.solidBg' }),
                                        },
                                    }}
                                >
                                    <StepButton onClick={() => setActiveStep(0)}>
                                        <Typography>Carrinho</Typography>
                                    </StepButton>

                                </Step>

                                <Step
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    indicator={
                                        <StepIndicator
                                            variant="solid"
                                            color={activeStep < 1 ? 'neutral' : 'primary'}>
                                            {
                                                activeStep <= 1 ? <LocalPostOffice /> : <Check />
                                            }
                                        </StepIndicator>
                                    }

                                    sx={{
                                        '&::after': {
                                            ...(activeStep > 1 && { bgcolor: 'primary.solidBg' }),
                                        },
                                    }}
                                >
                                    <StepButton onClick={() => setActiveStep(1)}>
                                        <Typography>Endereço</Typography>
                                    </StepButton>
                                </Step>

                                <Step
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    indicator={
                                        <StepIndicator
                                            variant="solid"
                                            color={activeStep < 2 ? 'neutral' : 'primary'}>
                                            {
                                                activeStep <= 2 ? <Call /> : <Check />
                                            }
                                        </StepIndicator>
                                    }

                                    sx={{
                                        '&::after': {
                                            ...(activeStep > 2 && { bgcolor: 'primary.solidBg' }),
                                        },
                                    }}
                                >
                                    <StepButton onClick={() => setActiveStep(2)}>
                                        <Typography>Contato</Typography>
                                    </StepButton>
                                </Step>

                                <Step
                                    orientation={isMobile ? 'vertical' : 'horizontal'}
                                    indicator={
                                        <StepIndicator
                                            variant="solid"
                                            color={activeStep < 3 ? 'neutral' : 'primary'}>
                                            {
                                                activeStep <= 3 ? <LocalShipping /> : <Check />
                                            }
                                        </StepIndicator>
                                    }
                                >
                                    <StepButton onClick={() => setActiveStep(3)}>
                                        <Typography>Finalizar</Typography>
                                    </StepButton>
                                </Step>
                            </Stepper>
                        </Grid>
                        <Grid md={12} xs={12} sx={isMobile ? {} : {marginTop: "2em"}}>
                        {
                            activeStep === 0 &&
                            <CartStep handleStepper={handleStepper} />
                        }
                        {
                            activeStep === 1 &&
                            <AddressStep handleStepper={handleStepper} handleAddAddress={() => console.log("add ao usuario um novo endereço de entrega")} />
                        }
                        {
                            activeStep === 2 &&
                            <ContactStep handleStepper={handleStepper} handleNewContact={() => console.log("add um novo contato ao usuario")} />
                        }
                        {
                            activeStep === 3 &&
                            <FinalStep handleStepper={handleStepper} handleFinalization={() => console.log("comprou")} />
                        }
                        </Grid>
                    </Grid>
                }
            </Grid>


            {
                !shop?.length &&
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
                    <Typography level="h2">Seu Carrinho está vazio!</Typography>
                    <Box sx={{ display: "flex", gap: "1em" }}>
                        <Button color="primary" onClick={() => nav("/criar")} size="lg">Nova Caneca</Button>
                        {
                            user.user &&
                            <Button color="secondary" onClick={() => nav("/colecao")} size="lg">Continuar Navegando</Button>
                        }
                    </Box>
                </Box>
            }
        </Stack >
    )
}