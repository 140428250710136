import {
	Box,
	Button,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Input,
	Radio,
	Tooltip,
	Typography,
} from "@mui/joy";

import "./ContactStep.scss";
import StepBody from "../StepBody";
import { Delete, EditNote } from "@mui/icons-material";
import { useState } from "react";
import { ModalAlert } from "../../global_components/Modal";
import CardInfo from "../../global_components/CardInfo";

export default function ContactStep(props: { handleNewContact: () => void; handleStepper: (value: number) => void }) {
	const [modal, setModal] = useState<boolean>(false);
	const [modalAlert, setModalAlert] = useState<boolean>(false);

	const [contact, setContact] = useState({
		id: "1",
		name: "Eu",
		phone: "(11) 98874-2207",
		email: "andrempacini@gmail.com",
	});
	const [newContact, setNewContact] = useState<{ id: string; name: string; phone: string; email: string }>();

	return (
		<StepBody handleStepper={props.handleStepper} step={2}>
			<Grid md={12} container>
				<Grid md={4}>
					<CardInfo
						cardTitle={contact.name}
						cardActions={false}
						isIconButton={true}
						haveSecondBtn={true}
						firstIcon={<EditNote />}
						firstIconTitle="Editar"
						firstIconColor="primary"
						firstIconTooltipColor="primary"
						firstIconTooltipVariant="outlined"
						secondIcon={<Delete />}
						secondIconTitle="Deletar"
						secondIconColor="danger"
						secondIconTooltipColor="danger"
						secondIconTooltipVariant="outlined"
						handleFirstIcon={() => setModal(true)}
						handleSecondIcon={() => setModalAlert(true)}
					>
						<Typography level="body-sm"><Typography level="title-md">Tel.: </Typography>{contact.phone}</Typography>
						<Typography level="body-sm"><Typography level="title-md">E-mail: </Typography>{contact.email}</Typography>
					</CardInfo>
				</Grid>
			</Grid>
			<Grid md={12}>
				<Divider />
			</Grid>
			<Grid md={12} container>
				<Grid md={12}>
					<Typography>Adicionar novo contato</Typography>
				</Grid>
				<Grid md={4} xs={8}>
					<FormControl>
						<FormLabel>Telefone</FormLabel>
						<Input color="primary" placeholder="(xx)9xxxx-xxxx" variant="outlined" value={newContact?.phone}></Input>
					</FormControl>
				</Grid>
				<Grid md={8} xs={12}>
					<FormControl>
						<FormLabel>E-mail</FormLabel>
						<Input color="primary" placeholder="E-mail" variant="outlined" value={newContact?.email}></Input>
					</FormControl>
				</Grid>
				<Grid md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button onClick={() => props.handleNewContact()}>Adicionar</Button>
				</Grid>
			</Grid>

			<ModalAlert
				openModal={modalAlert}
				title={contact.name}
				content="Deseja deletar esse endereço?"
				confirmText="Deletar"
				cancelText="Cancelar"
				confirmTextColor="danger"
				handleConfirmation={() => console.log("deleta")}
				handleCloseModal={() => setModalAlert(false)}
				handleCancelOperation={() => setModalAlert(false)}
			/>
		</StepBody>
	);
}
