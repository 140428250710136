import { fetchAuthSession, fetchUserAttributes} from "aws-amplify/auth";
import { useState } from "react";

async function currentSession() {
  try {
    let attr = await fetchUserAttributes();
    console.log("User attributes: ", attr);
    return attr.email + " " + attr.name;
  } catch (err) {
    console.log(err);
  }
}

async function getToken() {
  try {
    const { accessToken } = (await fetchAuthSession()).tokens ?? {};
    console.log(accessToken);
    console.log(accessToken?.toString());
    // let request = new Request(
    //   "https://5gbur885xa.execute-api.sa-east-1.amazonaws.com/prod/api/v1/product/1",
    //   {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }
    // );
    // fetch(request)
    //   .then((response) => {
    //     if (!response.ok) {
    //       throw new Error("Network response was not ok");
    //     }
    //     return response.json();
    //   })
    //   .then((data) => {
    //     // Work with JSON data here
    //     console.log(data);
    //   })
    //   .catch((error) => {
    //     console.error("There was a problem with the fetch operation:", error);
    //   });
    return accessToken?.toString();
  } catch (err) {
    console.log(err);
  }
}

function GetCurrentSession() {
  const [accessToken, setAccessToken] = useState("");

  async function handleClick() {
    const token = await getToken();
    setAccessToken(token ?? "");
    console.log(accessToken)
  }

  return (
    <>
      <button onClick={handleClick}>Get current token</button>
      <p>Access Token: {accessToken}</p>
    </>
  );
}

export default GetCurrentSession;
