import './area.scss';
import cattoMug from "../../../assets/cattoMug.png";

import { Helmet } from "react-helmet";

import { Box, Divider, Grid, Skeleton, Stack, Typography } from "@mui/joy";
import PageHeader from "../../../components/global_components/PageHeader";
import Slider from '../../../components/global_components/Slider';

import { getProjects } from '../../../services/projectService';
import { useEffect, useState } from 'react';
import collectionCard from '../../../interfaces/collectionCard';
import CardPreview from '../../../components/global_components/CardPreview';
import { Link, useNavigate } from 'react-router-dom';
import EmptyCollection from '../../../components/global_components/EmptyCollection';
import { useCart } from '../../../router/cartProducts';

export default function UserArea({toEdit}) {
    const nav = useNavigate();
    const product = useCart();
    const [apiData, setApiData] = useState<collectionCard[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const handleAPI = async () => {
            setIsLoading(true);
            let projects = await getProjects();
            setIsLoading(false);
            setApiData(projects.filter((x: any) => !x?.Id.startsWith("MediaIds")));

        }
        handleAPI()
        userRecentProjects()
    }, [])

    function userRecentProjects() {
        if (isLoading) {
            return (
                <Box>
                    {[1].map((key) => (
                        <Skeleton key={key} animation="wave" variant="rectangular" width="300px" height="300px" sx={{ borderRadius: "1rem" }} />
                    ))}
                </Box>
            )
        }
        let data = apiData.sort((a, b) => {
            if (b.CreatedAt > a.CreatedAt)
                return 1
            if (b.CreatedAt < a.CreatedAt)
                return -1
            return 0
        }).slice(0, 3);

        return (
            data.map((info: collectionCard) => {
                return (
                    <Box key={info.Id}>
                        <CardPreview
                            id={info.Id}
                            title={info.Title}
                            imgSrc={cattoMug}
                            showFavorite={false}
                            menu={true}
                            firstMenuText="Editar"
                            secondMenuText="Carrinho"
                            handleFirstMenuItem={() => { toEdit(info); nav('/criar') }}
                            handleSecondMenuItem={() => { product.addToCart(data) }}
                            handleCardEvent={() => { toEdit(info); nav('/criar') }}
                            width='300px'
                            height='300px'
                        />
                    </Box>
                )
            })
        )
    }

    function communityProjects() {
        if (isLoading) {
            return (
                <Box sx={{ display: "flex", gap: "1em" }}>
                    {[1, 2, 3, 4].map((key) => (
                        <Skeleton key={key} animation="wave" variant="rectangular" width="300px" height="300px" sx={{ borderRadius: "1rem" }} />
                    ))}
                </Box>
            )
        }

        return (
            apiData.map((info) => {
                return (
                    <div key={info.Id}>
                        <CardPreview
                            id={info.Id}
                            imgSrc={cattoMug}
                            menu={true}
                            title={info.Title}
                            showFavorite={false}
                            firstMenuText='Salvar'
                            secondMenuText='Editar'
                            // handleFirstMenuItem={() => { toEdit(data); nav('/criar') }}
                            // handleSecondMenuItem={() => { product.addToCart(data); setSnackbar(true); setSnackbarProduct(data.Title) }}

                            width='300px'
                            height='300px'
                        />
                    </div>
                )
            })
        )

    }

    return (
        <Box>
            <Helmet>
                <title>Fizz | Minha Área</title>
            </Helmet>
            <Stack spacing={2} padding={{ md: "2em", xs: "0.5em 0.5em" }} maxWidth={1300} width="100vw">
                <PageHeader title="Minha Área" />
                <Grid container spacing={2} textAlign={"center"} flexGrow={1}>
                    <Grid md={6} xs={12}>
                        <Box className="gridBox">
                            {
                                apiData.length === 0 && isLoading === false &&
                                <EmptyCollection />
                            }
                            {
                                (apiData.length > 0 || isLoading === true) &&
                                <>
                                    <Typography level='h4'>Designs recentes</Typography>
                                    <Box sx={{ position: "relative", maxWidth: "320px" }}>
                                        <Slider>
                                            {userRecentProjects()}
                                        </Slider>
                                    </Box>
                                    <Divider sx={{ margin: "1em 0" }} />
                                    <Link to='/colecao'>Ver todos meus projetos</Link>
                                </>
                            }
                        </Box>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <Box className="gridBox" sx={{ alignItems: "normal" }}>
                            <Typography level='h4'>Meus pedidos</Typography>
                            <Grid container spacing={4} flexGrow={1} padding={"2em"}>
                                <Grid md={6}>
                                    <Typography level='title-lg'>Pedido</Typography>
                                    <Link to='/pedidos'>N.º {"999999999"}</Link>
                                </Grid>
                                <Grid md={6}>
                                    <Typography level='title-lg'>Status</Typography>
                                    <Typography>{"A caminho"}</Typography>
                                </Grid>
                            </Grid>
                            <Divider sx={{ margin: "1em 0" }} />
                            <Link to='/pedidos'>Ver todos meus pedidos</Link>
                        </Box>
                    </Grid>

                    <Grid md={12}>
                        <Box className="gridBox">
                            <Typography level='h4'>Confira nossas promoções!</Typography>
                            <Box sx={{ width: "100%", display: "flex", gap: "1em", marginTop: "2em" }}>
                                {
                                    [1, 2, 3, 4].map((key) => (
                                        <Skeleton key={key} animation="wave" variant="rectangular" width="300px" height="300px" sx={{ borderRadius: "1rem" }} />
                                    ))
                                }
                            </Box>
                        </Box>
                    </Grid>

                    <Grid md={12}>
                        <Box className="gridBox">
                            <Typography level='h4'>Novos da comunidade!</Typography>
                            <Box sx={{ position: "relative", width: "100%", padding: "1em" }}>
                                <Slider>
                                    {communityProjects()}
                                </Slider>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}