import ImageMenu from "./img_menu/img_menu";
import TextMenu from "./text_menu/text_menu";
import EmojiMenu from "./emoji_menu/emoji_menu";
import BorderMenu from "./sticker_menu/sticker_menu";
import OptionsMenu from "./options_menu/options_menu";
import Tutorial from "../../global_components/tutorial";
import { ElementsContext } from "../../../pages/create/create";

import "./object_menu.scss";

import { Divider, IconButton } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import BackgroundMenu from "./img_menu/background_menu/background_menu";

export default function ObjectMenu(props: {
	index?: number | null;
	bgImage?: any;
	mugTitle: string;
	mugDescription: string;
	handleEdit: () => void;
	handleSave: (toCart: boolean) => void;
	handleStep: (value: number | null) => void;
	handleMenuLoading: (value: [boolean, string]) => void;
}) {
	const { elements } = useContext(ElementsContext);
	const [activeMenu, setActiveMenu] = useState<string | null>(null);
	const [, setTitle] = useState(props.mugTitle ?? "Minha Caneca");
	const [, setDescription] = useState(props.mugDescription ?? "");

	useEffect(() => {
		setActiveMenu(null);
		setTitle(props.mugTitle);
		setDescription(props.mugDescription);
	}, [elements, props]);

	return (
		<Tutorial
			tutorialText={
				"Aqui você pode navegar entre as abas do editor para personalizar sua caneca!\nNa aba 'Texto', por exemplo, você pode adicionar qualquer texto que escrever, assim como selecionar a fonte e cor do seu texto. "
			}
			open={props.index === 0}
			handleSteps={props.handleStep}
			showPreviousBtn={false}
			complete={25}
		>
			<div className="object_menu">
				<div className={`context_menu ${activeMenu ? "active" : ""}`}>
					<div className="header">
						{activeMenu === "image" && <h3>Imagem</h3>}
						{activeMenu === "text" && <h3>Texto</h3>}
						{activeMenu === "emoji" && <h3>Emoji</h3>}
						{activeMenu === "border" && <h3>Sticker</h3>}
						{activeMenu === "more" && <h3>Mais opções</h3>}
						<IconButton onClick={() => setActiveMenu(null)} variant="plain">
							<span className="material-symbols-rounded">close</span>
						</IconButton>
					</div>
					<div className="body">
						{activeMenu === "image" && (
							<ImageMenu bgImage={props.bgImage} handleMenuLoading={props.handleMenuLoading} />
						)}
						{activeMenu === "text" && <TextMenu />}
						{activeMenu === "emoji" && <EmojiMenu />}
						{activeMenu === "border" && <BorderMenu />}
						{activeMenu === "more" && (
							<OptionsMenu
								mugTitle={props.mugTitle}
								mugDescription={props.mugDescription}
								handleEdit={props.handleEdit}
								handleSave={props.handleSave}
							/>
						)}
					</div>
				</div>
				<div className="nav_menu">
					<div
						className={`menu_option ${activeMenu === "image" ? "active" : ""}`}
						onClick={() => setActiveMenu("image")}
					>
						<span className="material-symbols-rounded">image</span>
						<p>Foto</p>
					</div>
					<div
						className={`menu_option ${activeMenu === "emoji" ? "active" : ""}`}
						onClick={() => setActiveMenu("emoji")}
					>
						<span className="material-symbols-rounded">add_reaction</span>
						<p>Emoji</p>
					</div>
					<div
						className={`menu_option ${activeMenu === "border" ? "active" : ""}`}
						onClick={() => setActiveMenu("border")}
					>
						<span className="material-symbols-rounded">note_stack_add</span>
						<p>Sticker</p>
					</div>
					<div className={`menu_option ${activeMenu === "text" ? "active" : ""}`} onClick={() => setActiveMenu("text")}>
						<span className="material-symbols-rounded">text_fields</span>
						<p>Texto</p>
					</div>
					<Tutorial
						tutorialText={
							"Quando estiver satisfeito com sua criação, pode escolher entre salvar em sua coleção ou adicioná-la direto ao carrinho!\nPode encontrar essas opções na aba 'Mais'."
						}
						open={props.index === 3}
						handleSteps={props.handleStep}
						showNextBtn={false}
						complete={100}
					>
						<div
							className={`menu_option ${activeMenu === "more" ? "active" : ""}`}
							onClick={() => setActiveMenu("more")}
						>
							<span className="material-symbols-rounded">add</span>
							<p>Mais</p>
						</div>
					</Tutorial>
				</div>
			</div>
		</Tutorial>
	);
}
