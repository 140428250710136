import React from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box } from "@mui/joy";
import BackgroundMenu from "./background_menu/background_menu";
import UploadMenu from "./upload_menu/upload_menu";

export default function ImageMenu(props: { handleMenuLoading: (value: [boolean, string]) => void; bgImage?: any }) {
	const [index, setIndex] = React.useState<number | null>(0);

	return (
		<AccordionGroup size="lg" sx={{ height: "90%" }}>
			<Accordion
				expanded={index === 0}
				onChange={(event, expanded) => {
					setIndex(expanded ? 0 : 1);
				}}
			>
				<AccordionSummary sx={{ padding: "0em" }}>Suas fotos</AccordionSummary>
				<AccordionDetails>
					<UploadMenu handleMenuLoading={props.handleMenuLoading} />
				</AccordionDetails>
			</Accordion>
			<Accordion
				expanded={index === 1}
				onChange={(event, expanded) => {
					setIndex(expanded ? 1 : 0);
				}}
				sx={{ maxHeight: "100%" }}
			>
				<AccordionSummary sx={{ padding: "0px" }}>Background</AccordionSummary>
				<AccordionDetails>
					<Box height="100%" sx={{ overflow: "hidden", overflowY: "auto" }} padding="0em">
						<BackgroundMenu />
					</Box>
				</AccordionDetails>
			</Accordion>
		</AccordionGroup>
	);
}
