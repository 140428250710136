import cattoMug from "../../../assets/cattoMug.png";
// import InputMask from "react-input-mask";

import "./CardStep.scss";

import { Delete } from "@mui/icons-material";
import {
    Grid,
    Typography,
    Button,
    Stack,
    Divider,
    Input,
} from "@mui/joy";
import { ModalAlert } from "../../global_components/Modal";
import { useState } from "react";
// import { deliveryData } from "../../../pages/user/cart/Data";
import { useCart } from "../../../router/cartProducts";
import StepBody from "../StepBody";

export default function CartStep(props: {
    handleStepper: (value: number) => void
}) {
    const product = useCart();
    const [modalClear, setModalClear] = useState<boolean>(false);
    const [modalRemove, setModalRemove] = useState<boolean>(false);
    const [toRemove, setToRemove] = useState(null);
    // const [deliveryValue, setDeliveryValue] = useState(null);
    // const [selectedCompany, setSelectedCompany] = useState(null);
    // const [company, setCompany] = useState([]);
    // const [cep, setCep] = useState("");
    // const [cepDeliveryInfo, setCepDeliveryInfo] = useState("");
    // const [totalItems, setTotalItems] = useState(0);
    // const [total, setTotal] = useState(0);
    const [shop, setShop] = useState(product.productList);

    // useEffect(() => {
    //     setTotalItems(
    //         shop.reduce((accumulator, currentValue) => {
    //             return accumulator + currentValue.price * currentValue.quantity;
    //         }, 0)
    //     );
    // }, [shop]);

    // useEffect(() => {
    //     setCompany(deliveryData);
    // }, []);

    // useEffect(() => {
    //     setTotal(totalItems + (deliveryValue?.price ? deliveryValue?.price : 0));
    // }, [deliveryValue, totalItems]);

    function handleQuantityUpdate(index, qtd) {
        const newQuantity = shop.map((c, i) => {
            if (i === index) {
                c.quantity = qtd <= 0 ? 1 : qtd;
                if (qtd === 0) {
                    setToRemove(c);
                    setModalRemove(true);
                }
            }
            return c;
        });
        setShop(newQuantity);
    }

    function handleCloseRemoveModal() {
        setToRemove(null);
        setModalRemove(false);
    }

    function handleRemoveItem() {
        setToRemove(null);
        product.setProductList(product.productList.filter((obj) => obj.Id !== toRemove.Id));
        setShop(shop.filter((obj) => obj.Id !== toRemove.Id));
        setModalRemove(false);
    }

    function handleCloseClearModal() {
        setModalClear(false);
    }

    function handleClearCart() {
        product.setProductList([]);
        setShop([]);
        setModalClear(false);
    }
    return (
        <StepBody handleStepper={props.handleStepper} step={0}>
            <Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "space-between", marginBottom: "5em" }}>
                <Typography level="h2">Produtos</Typography>
                <Button variant="outlined" color="danger" onClick={() => setModalClear(true)} size="sm">
                    <Delete />
                    Limpar Carrinho
                </Button>
            </Grid>
            <Grid md={12} xs={12}>
                <Stack divider={<Divider sx={{ margin: "1em 0" }} />}>
                    {shop.map((item, index) => {
                        return (
                            <Grid
                                md={12}
                                xs={12}
                                container
                                spacing={2}
                                key={item.Id}
                                sx={{ display: "flex", flexGrow: "1", justifyContent: "space-between" }}
                            >
                                <Grid md={2} xs={12} container alignItems="center">
                                    <img className="mugImage" src={cattoMug} alt="img" />
                                </Grid>
                                <Grid md={10} xs={12} container alignItems="center">
                                    <Grid md={5} xs={12}> 
                                        <Typography level="h4">{item?.Title}</Typography>
                                        {/* <Typography level="body-sm">{item.model}</Typography> */}
                                        <Typography level="body-sm">Modelo da Caneca</Typography>
                                    </Grid>
                                    <Grid md={1} xs={2} sx={{ gap: "0.5em", marginRight: "0.5em" }}>
                                        <Typography level="body-md" sx={{ marginBottom: "0.5em" }}>Qtd.</Typography>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                handleQuantityUpdate(index, item.quantity + 1);
                                            }}
                                        >
                                            +
                                        </Button>
                                        <Input
                                            type="number"
                                            className="qtdInput"
                                            value={item.quantity}
                                            onChange={(evt) => handleQuantityUpdate(index, evt.target.value)}
                                            variant="plain"
                                        >
                                            {item.quantity}
                                        </Input>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                handleQuantityUpdate(index, item.quantity - 1);
                                            }}
                                        >
                                            -
                                        </Button>
                                    </Grid>
                                    <Grid md={2} xs={3}>
                                        <Typography level="title-md">Unitário</Typography>
                                        <Typography>R$ {item.price?.toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid md={2} xs={3}>
                                        <Typography level="title-md">Total</Typography>
                                        <Typography>R$ {(item.price * item.quantity)?.toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid md={1} xs={3}>
                                        <Button
                                            variant="outlined"
                                            color="danger"
                                            onClick={() => {
                                                setToRemove(item);
                                                setModalRemove(true);
                                            }}
                                        >
                                            Remover
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Stack>
            </Grid>


            <ModalAlert
                openModal={modalRemove}
                title="Deseja remover este item?"
                content={toRemove?.Title}
                confirmTextColor="danger"
                confirmText="Remover"
                cancelText="Cancelar"
                handleConfirmation={handleRemoveItem}
                handleCancelOperation={handleCloseRemoveModal}
                handleCloseModal={handleCloseRemoveModal}
            />

            <ModalAlert
                openModal={modalClear}
                title="Limpar Carrinho?"
                content="Deseja remover todos os itens do carrinho?"
                confirmTextColor="danger"
                confirmText="Limpar Carrinho"
                cancelText="Cancelar"
                handleConfirmation={handleClearCart}
                handleCancelOperation={handleCloseClearModal}
                handleCloseModal={handleCloseClearModal}
            />
            {/* <Stack spacing={2}>
					<Box>
						<Typography level="title-lg">Total:</Typography>
						<Typography>R$ {totalItems?.toFixed(2)}</Typography>
						<Divider sx={{margin: "1em 0"}} />
						<Typography level="body-sm" sx={{marginBottom: "1em"}}>
							*O valor acima refere-se ao valor da caneca. Seu atendimento será continuado através do e-mail ou telefone
							que você disponibilizar, e, por lá será informado o valor do frete!
						</Typography>

						{
                            deliveryValue &&
                            <Box sx={{ marginBottom: "2em" }}>
                                <Typography level="title-lg">Total com Frete:</Typography>
                                <Typography>R$ {total?.toFixed(2)}</Typography>
                            </Box>
                        }
					</Box>
					{
                        deliveryValue &&
                        <Divider />
                    }
                    <Box sx={{ display: "flex", gap: "1em", alignItems: "center" }}>
                        <FormControl>
                            <FormLabel>Calcule o frete do seu pedido</FormLabel>
                            <InputMask mask="99999-999" value={cep} onChange={(evt) => setCep(evt.target.value)} maskChar=" ">
                                {
                                    //@ts-ignore
                                    () => (<Input value={cep} placeholder="00000-000" />)
                                }
                            </InputMask>
                            <FormHelperText><a className="cepLink" target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer">Não sei meu CEP</a></FormHelperText>
                        </FormControl>
                        <Button sx={{ height: "fit-content" }} size="sm" onClick={() => setCepDeliveryInfo(cep)}>Ok</Button>
                    </Box>
                    {
                        cepDeliveryInfo &&
                        company.map((item) => {
                            return (
                                <Stack key={item.id}>
                                    <Box className={(`company ${selectedCompany === item.Id ? "active" : ""}`)} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0.5em" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography><Typography level="title-md">Empresa: </Typography>{item.company}</Typography>
                                            <Typography><Typography level="title-md">Valor: </Typography>R$ {(item.price)?.toFixed(2)}</Typography>
                                            <Typography><Typography level="title-md">Prazo: </Typography>{item.delivery}</Typography>
                                        </Box>
                                        <Radio variant="outlined" color="primary" onChange={() => { setDeliveryValue(item); setSelectedCompany(item.Id) }} checked={item.Id === selectedCompany} />
                                    </Box>
                                </Stack>
                            )
                        })
                    }
					<Button onClick={() => nav("preBuy", { state: { key: "value" } })} color="primary">Comprar</Button>
					<Button onClick={() => props.handleStepper(+1)} color="primary">
						Avançar
					</Button>
					<Button color="secondary" onClick={() => nav("/colecao")}>
						Continuar Comprando
					</Button>
				</Stack> */}
        </StepBody>

    );
}
