import { Delete, EditNote } from "@mui/icons-material";
import "./AddressStep.scss";

import {
	Button,
	Divider,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Typography,
} from "@mui/joy";

import StepBody from "../StepBody";
import { ModalForm, ModalAlert } from "../../global_components/Modal";
import { useState } from "react";
import CardInfo from "../../global_components/CardInfo";

export default function AddressStep(props: { handleAddAddress: () => void; handleStepper: (value: number) => void }) {
	const [modal, setModal] = useState<boolean>(false);
	const [modalAlert, setModalAlert] = useState<boolean>(false);
	const [address, setAddress] = useState({
		id: "1",
		name: "Casa",
		cep: "05065-010",
		street: "Rua William Speers",
		number: "258",
		neighborhood: "Lapa de Baixo",
	});
	const [newAddress, setNewAddress] = useState<{
		id: string;
		name: string;
		cep: string;
		street: string;
		number: string;
		neighborhood: string;
	}>();
	return (
		<StepBody handleStepper={props.handleStepper} step={1}>
			<Grid md={12} container>
				<Grid md={4}>
					<CardInfo
						cardTitle="Endereço"
						cardActions={false}
						isIconButton={true}
						haveSecondBtn={true}
						firstIcon={<EditNote />}
						firstIconTitle="Editar"
						firstIconColor="primary"
						firstIconTooltipColor="primary"
						firstIconTooltipVariant="outlined"
						secondIcon={<Delete />}
						secondIconTitle="Deletar"
						secondIconColor="danger"
						secondIconTooltipColor="danger"
						secondIconTooltipVariant="outlined"
						handleFirstIcon={() => setModal(true)}
						handleSecondIcon={() => setModalAlert(true)}
					>
						<Typography level="body-sm"><Typography level="title-md">Cep: </Typography>{address.cep}</Typography>
						<Typography level="body-sm"><Typography level="title-md">Rua: </Typography>{address.street}</Typography>
						<Typography level="body-sm"><Typography level="title-md">Nº: </Typography>{address.number}</Typography>
					</CardInfo>
					{/* <Box className="addressWrapper">
						<Radio variant="soft" color="primary" sx={{marginRight: "0.5em"}} />
						<Box className="addressInfo">
							<Typography level="title-lg" sx={{ marginBottom: "0.5em" }}>
								{address.name}
							</Typography>
							<Typography level="title-sm">{`CEP: ${address.cep}`}</Typography>
							<Typography level="title-sm">{`Rua: ${address.street}`}</Typography>
							<Typography level="title-sm">{`Nº: ${address.number}`}</Typography>
						</Box>
						<Box sx={{display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%"}}>
							<Tooltip title="Editar" size="sm" color="primary" variant="outlined">
								<IconButton onClick={() => setModal(true)} color="primary">
									<EditNote />
								</IconButton>
							</Tooltip>
							<Tooltip title="deletar" size="sm" color="danger" variant="outlined">
								<IconButton onClick={() => setModalAlert(true)} color="danger">
									<Delete />
								</IconButton>
							</Tooltip>
						</Box>
					</Box> */}
				</Grid>
			</Grid>
			<Grid md={12}>
				<Divider />
			</Grid>
			<Grid md={12} container>
				<Grid md={12}>
					<Typography>Adicionar novo endereço</Typography>
				</Grid>
				<Grid md={4} xs={8}>
					<FormControl>
						<FormLabel>CEP</FormLabel>
						<Input color="primary" placeholder="CEP" variant="outlined" value={newAddress?.cep}></Input>
					</FormControl>
				</Grid>
				<Grid md={8} xs={12}>
					<FormControl>
						<FormLabel>Endereço</FormLabel>
						<Input color="primary" placeholder="Endereço" variant="outlined" value={newAddress?.street}></Input>
					</FormControl>
				</Grid>
				<Grid md={4} xs={4}>
					<FormControl>
						<FormLabel>Número</FormLabel>
						<Input color="primary" placeholder="Número" variant="outlined" value={newAddress?.number}></Input>
					</FormControl>
				</Grid>
				<Grid md={8} xs={12}>
					<FormControl>
						<FormLabel>Bairro</FormLabel>
						<Input color="primary" placeholder="Bairro" variant="outlined" value={newAddress?.neighborhood}></Input>
					</FormControl>
				</Grid>
				<Grid md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button onClick={() => props.handleAddAddress()}>Adicionar</Button>
				</Grid>
			</Grid>

			<ModalAlert
				openModal={modalAlert}
				title={address.name}
				content="Deseja deletar esse endereço?"
				confirmText="Deletar"
				cancelText="Cancelar"
				confirmTextColor="danger"
				handleConfirmation={() => console.log("deleta")}
				handleCloseModal={() => setModalAlert(false)}
				handleCancelOperation={() => setModalAlert(false)}
			/>

			<ModalForm
				openModal={modal}
				modalSize="lg"
				modalTitle="Endereço"
				confirmText="Salvar"
				confirmTextColor="primary"
				modalHeight="500px"
				modalWidth="500px"
				handleConfirmation={() => console.log("PUT")}
				handleCancelOperation={() => setModal(false)}
				handleCloseModal={() => setModal(false)}
			>
				<Grid container spacing={2} sx={{ width: "100%" }}>
					<Grid md={12} xs={8}>
						<FormControl>
							<FormLabel>{"Nome(Opcional)"}</FormLabel>
							<Input
								color="primary"
								placeholder="CEP"
								variant="outlined"
								value={address.name}
								onChange={(evt) => {
									address.name = evt.target.value;
									setAddress({ ...address });
								}}
							></Input>
						</FormControl>
					</Grid>
					<Grid md={4} xs={8}>
						<FormControl>
							<FormLabel>CEP</FormLabel>
							<Input
								color="primary"
								placeholder="CEP"
								variant="outlined"
								value={address.cep}
								onChange={(evt) => {
									address.cep = evt.target.value;
									setAddress({ ...address });
								}}
							></Input>
						</FormControl>
					</Grid>
					<Grid md={8} xs={12}>
						<FormControl>
							<FormLabel>Endereço</FormLabel>
							<Input
								color="primary"
								placeholder="Endereço"
								variant="outlined"
								value={address.street}
								onChange={(evt) => {
									address.street = evt.target.value;
									setAddress({ ...address });
								}}
							></Input>
						</FormControl>
					</Grid>
					<Grid md={4} xs={4}>
						<FormControl>
							<FormLabel>Número</FormLabel>
							<Input
								color="primary"
								placeholder="Número"
								variant="outlined"
								value={address.number}
								onChange={(evt) => {
									address.number = evt.target.value;
									setAddress({ ...address });
								}}
							></Input>
						</FormControl>
					</Grid>
					<Grid md={8} xs={12}>
						<FormControl>
							<FormLabel>Bairro</FormLabel>
							<Input
								color="primary"
								placeholder="Bairro"
								variant="outlined"
								value={address.neighborhood}
								onChange={(evt) => {
									address.neighborhood = evt.target.value;
									setAddress({ ...address });
								}}
							></Input>
						</FormControl>
					</Grid>
				</Grid>
			</ModalForm>
		</StepBody>
	);
}
