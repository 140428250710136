import {
	Modal,
	DialogTitle,
	Divider,
	DialogContent,
	DialogActions,
	Button,
	CircularProgress,
	ModalDialog,
	Stack
} from "@mui/joy";

//MODAL DE ALERTA
export function ModalAlert(props: {
	openModal: boolean;
	title: string;
	content?: string;
	confirmText?: string;
	cancelText?: string;
	confirmTextColor?: "danger" | "success" | "secondary" | "primary";
	cancelTextColor?: "danger" | "success" | "secondary" | "primary";
	handleCloseModal?: () => void;
	handleConfirmation: () => void;
	handleCancelOperation: () => void;
}) {
	return (
		<Modal
			open={props.openModal || false}
			onClose={() => {
				props.handleCloseModal();
			}}
		>
			<ModalDialog variant="outlined" role="alertdialog" sx={{ alignItems: "center" }}>
				<DialogTitle>{props.title}</DialogTitle>
				<Divider />
				<DialogContent sx={{ whiteSpace: "pre-wrap" }}>{props.content}</DialogContent>
				<DialogActions>
					<Button
						variant="solid"
						color={props.confirmTextColor || "success"}
						onClick={() => props.handleConfirmation()}
					>
						{props.confirmText || "Remover"}
					</Button>
					<Button color={props.cancelTextColor || "neutral"} onClick={() => props.handleCancelOperation()}>
						{props.cancelText || "Cancelar"}
					</Button>
				</DialogActions>
			</ModalDialog>
		</Modal>
	);
}

//MODAL PARA FORMULÁRIOS
export function ModalForm(props: {
	openModal: boolean;
	modalSize?: "sm" | "md" | "lg";
	modalTitle: string;
	modalHeight?: string;
	modalWidth?: string;
	confirmTextColor?: "success" | "danger" | "neutral" | "warning" | "primary" | "secondary";
	confirmText?: string;
	cancelText?: string;
	handleConfirmation: () => void;
	handleCancelOperation: () => void;
	handleCloseModal: () => void;
	children: any;
}) {
	return (
		<Modal
			open={props.openModal || false}
			onClose={(event, reason) => {
				if (reason && reason === "backdropClick") {
					return;
				} else if (reason && reason === "escapeKeyDown") {
					return;
				} else {
					props.handleCloseModal();
				}
			}}
		>
			<ModalDialog
				size={props.modalSize}
				variant="outlined"
				sx={{ alignItems: "center", overflowX: "hidden", height: props.modalHeight, width: props.modalWidth }}
			>
				<DialogTitle>{props.modalTitle}</DialogTitle>
				<Divider />
				<DialogContent>{props.children}</DialogContent>
				<DialogActions>
					<Button
						variant="solid"
						color={props.confirmTextColor || "success"}
						onClick={() => props.handleConfirmation()}
					>
						{props.confirmText || "Remover"}
					</Button>
					<Button color="neutral" onClick={() => props.handleCancelOperation()}>
						{props.cancelText || "Cancelar"}
					</Button>
				</DialogActions>
			</ModalDialog>
		</Modal>
	);
}

//MODAL PARA LOADINGS
export function ModalLoading(props: { openModal: boolean; modalTitle: string; children?: any }) {
	return (
		<Modal
			open={props.openModal || false}
			onClose={(event, reason) => {
				return;
			}}
		>
			<ModalDialog size="md" variant="outlined" sx={{ alignItems: "center" }}>
				<DialogTitle>{props.modalTitle}</DialogTitle>
				<Divider />
				<DialogContent>{props.children || <CircularProgress />}</DialogContent>
			</ModalDialog>
		</Modal>
	);
}

export function ModalBig(props: {
	open: boolean;
	modalTitle: string;
	buttonTitle?: string;
	onBtnClick?: () => void;
	onClose: () => void;
	children?: any;
}) {
	const fullScreen = window.innerWidth <= 768;

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<ModalDialog layout={fullScreen ? "fullscreen" : "center"} size="lg" sx={{ width: "90vw", height: "80vh" }}>
				<DialogTitle>
					<Stack direction="row" justifyContent="space-between" sx={{ flexGrow: 1 }}>
						{props.modalTitle}
						{props.buttonTitle && (
							<Button color="success" onClick={props.onBtnClick}>
								{props.buttonTitle}
							</Button>
						)}
					</Stack>
				</DialogTitle>
				<Divider />
				<DialogContent>{props.children}</DialogContent>
			</ModalDialog>
		</Modal>
	);
}
