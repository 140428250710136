import { AddShoppingCart, Edit, Save } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/joy";
import { useState, useEffect } from "react";

export default function OptionsMenu(props: {
	mugTitle: string;
	mugDescription: string;
	handleEdit: () => void;
	handleSave: (toCart: boolean) => void;
}) {
	const [title, setTitle] = useState(props.mugTitle ?? "Minha Caneca");
	const [description, setDescription] = useState(props.mugDescription ?? "");

	useEffect(() => {
		setTitle(props.mugTitle);
		setDescription(props.mugDescription);
	}, [props]);
	return (
		<Stack>
			<Box sx={{ padding: "0.5em", display: "flex", justifyContent: "center" }}>
				<Typography level="h3">{props.mugTitle}</Typography>
				<Tooltip title="Editar" variant="outlined" color="primary">
					<IconButton color="primary" sx={{ marginLeft: "1em" }} onClick={() => props.handleEdit()}>
						<Edit />
					</IconButton>
				</Tooltip>
			</Box>
			<Divider />
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-evenly",
					padding: "1em 0",
				}}
			>
				<Button
					variant="outlined"
					sx={{ width: "35%" }}
					color="secondary"
					onClick={() => props.handleSave(false)}
				>
					<Save />
					Salvar
				</Button>
				<Button variant="outlined" sx={{ width: "35%" }} onClick={() => props.handleSave(true)}>
					<AddShoppingCart />
					Adicionar ao Carrinho
				</Button>
			</Box>
		</Stack>
	);
}
