export const background_data = [
    "whiteBG.jpg",
    "colorBomb.webp",
    "summerBack.jpg",
    "texture2.webp",
    "texture4.jpg",
    "FizzBackground.png",
    "officeDesk.jpg",
    "texture1.jpg",
    "texture3.webp",
    "diaDasMaes1.jpg",
    "diaDasMaes2.png",
    "diaDasMaes3.jpg",
    "diaDasMaes4.jpg",
    "diaDasMaes5.jpg",
]