import { Input, Textarea, Button } from "@mui/joy";
import { useState } from "react";

export default function SupportForm(props: {
    className: string,
    emailPlaceholder: string,
    messagePlaceholder: string,
    buttonText: string,
    maxWidth?: string
}) {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

    return (
        <form action="mailto:someone@example.com">
            <Input
                className={props.className}
                placeholder={props.emailPlaceholder}
                color="secondary"
                size="lg"
                type="email"
                value={email}
                required
                onChange={(evt) => setEmail(evt.target.value)}
                sx={{ marginBottom: "1em", maxWidth: props.maxWidth || "70%" }}
            >
            </Input>
            <Textarea
                className={props.className}
                placeholder={props.messagePlaceholder}
                minRows={7}
                maxRows={7}
                value={message}
                required
                onChange={(evt) => setMessage(evt.target.value)}
                color="secondary"
                sx={{ marginBottom: "1em" }}
            >
            </Textarea>
            <Button color="secondary" size="xl" onClick={() => console.log("email: ", email, " || message: ", message)} type="submit">{props.buttonText}</Button>
        </form>
    )
}