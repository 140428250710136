import { Person, LibraryBooks, Logout, Apps } from "@mui/icons-material";
import { Dropdown, MenuButton, Box, Avatar, Menu, MenuItem, Typography } from "@mui/joy";
import { Link } from "react-router-dom";

import { useUser } from "../../router/UserInfo";
import { signOut } from "aws-amplify/auth";

export default function LoginMenu(props: {
    color?: "primary" | "secondary",
    variant?: "solid" | "plain" | "outlined" | "soft"
}) {
    
    const user = useUser();

    async function handleSignOut() {
        await signOut()
    }

    return (
        <Dropdown>
            <MenuButton variant="plain" className="profileMenu">
                <Box className="profileWrapper">
                    <Typography className="text" textColor="white" sx={{marginRight: "0.5em"}}>{user.user?.name}</Typography>
                    <Avatar className="profile" variant={props.variant ?? "soft"} color={props?.color ?? "neutral"} />
                </Box>
            </MenuButton>
            <Menu>
                <MenuItem><Link to="/minha-area" className="topMenuLink"><Apps color="primary" /> Minha Área</Link></MenuItem>
                <MenuItem><Link to="/colecao" className="topMenuLink"><LibraryBooks color="primary" /> Minha Coleção</Link></MenuItem>
                <MenuItem><Link to="/profile" className="topMenuLink"><Person color="primary" /> Meu Perfil</Link></MenuItem>
                <MenuItem><p onClick={handleSignOut} className="topMenuLink"><Logout color="primary" /> Sair</p></MenuItem>
            </Menu>
        </Dropdown>
    )
}